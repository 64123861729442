/**
 * Slider
 * @constructor
 * @requires pubsub-js
 */
class Slider {

  constructor(){

    this.init = this.init.bind(this);

    this.init();

  }

  init( dom ){

    dom = dom || document;

    this.sliders = dom.qsa('[data-sliderConfig]');

    this.sliders.forEach(function(slider){


      var config = JSON.parse(slider.dataset.sliderconfig);

      console.log(config['id'])

      if( config['pagination'] ){
        config.pagination = {
          el: slider.querySelector('.swiper-pagination'),
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + '</span>';
          }
        }
      }
      if( config['breakpoints'] ){
        config.breakpoints = {
          320: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          600: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 10
          }
        }
      }
      if( config['navigation'] ){
        config.navigation = {
          nextEl: '.bt-slider.next',
          prevEl: '.bt-slider.prev',
        }
      }
      if( config['navigation-full-height'] ){
        config.navigation = {
          nextEl: '.custom-button.next',
          prevEl: '.custom-button.prev',
        }
      }

      if( config['navigation-mob'] ){
        config.navigation = {
          nextEl: '.custom-button_'+config['id']+'.next',
          prevEl: '.custom-button_'+config['id']+'.prev',
        }
      }

      if( config["external-nav"] ){
        config.on = {
          slideChange: function(){
            if( slider.sliderDom ){
              var slides = slider.closest('[data-sliderContainer]').qsa('[data-slideTo]');
              slides.forEach( (slide) => {
                slide.classList.remove('active');
              });
              var currentSlide = slider.closest('[data-sliderContainer]').querySelector('[data-slideTo="' + parseInt(slider.sliderDom.realIndex+1,10) + '"]');
              if( currentSlide ){
                currentSlide.classList.add('active');
              }

            }
          }
        }
      }
      if(!config['external-nav']){
        config.on = {};
        config.on.init = function(){
          if( this.isBeginning && this.isEnd ){
            slider.classList.add('all-visible');
          }
        };

        config.on.resize = function(swiper){
          if( this.isBeginning && this.isEnd ){
            slider.classList.add('all-visible');
          }
          else{
            slider.classList.remove('all-visible');
          }
        }
      }

      window.requestAnimationFrame(function(){
        slider.sliderDom = new Swiper(slider, config);
      });

      if( config.autoplay ){
        document.addEventListener('visibilitychange', function(){
          if( !slider.sliderDom ){
            return;
          }
          if( document.hidden ){
            slider.sliderDom.autoplay.stop();
          }
          else{
            slider.sliderDom.autoplay.start();
          }
        });
      }

    });

    var controls = document.querySelector('[data-slider-controls]');

    if( !document.querySelector('[data-slideTo]') ){
      return;
    }
    controls.qsa('[data-slideTo]').forEach( (control) => {

      control.addEventListener('click', function(e){
       
        var trigger = e.currentTarget,
            slide = +trigger.dataset.slideto,
            slider = trigger.closest('[data-sliderContainer]').querySelector('[data-sliderConfig]');


        slider.sliderDom.slideTo(slide);

      });

    });
  }

}

export default new Slider();
