if( !( "objectFit" in document.documentElement.style ) ){
  document.qsa('.o-fit').forEach( (imgParent) =>{

    if((imgParent.querySelector('img')) != null)
    {
      imgParent.style.backgroundImage = 'url('+imgParent.querySelector('img').src+')';
      imgParent.classList.add('object-fit');
    }

  });
}
