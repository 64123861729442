import ps from 'pubsub-js';
import fetcher from '@switch-company/fetcher';
import formUtils from '@switch-company/form-utils';
import sliders from './sliders';
import select from './select';
import toggle from './toggle';
import range from './range';

let logoPosition = 0
let isOccasion = true
let headerHeight = 0 
var _this
/**
 * Misc
 * @constructor
 * @requires pubsub-js
 * @requires fetcher
 * @requires form-utils
 */
class Misc {

  /**
   * Inits the module with necessary functions
   * @method
  **/
  constructor() {

    this._storageHandler = this._storageHandler.bind(this);
    this._fixHeader = this._fixHeader.bind(this);
    this._scroll = this._scroll.bind(this);
    this._submit = this._submit.bind(this);
    this._load = this._load.bind(this);
    this._popinVideo = this._popinVideo.bind(this);
    this._bind = this._bind.bind(this);
    this._serviceScroll = this._serviceScroll.bind(this);
    this._searchtab = this._searchtab.bind(this);
    this._resetForm = this._resetForm.bind(this);
    this.showResetBtn = this.showResetBtn.bind(this)
    this.printPage = this.printPage.bind(this)

    this._bind();
    this._fixHeader();

    _this = this

  }

  _bind() {

    document.body.listen('click', '[data-storage]', this._storageHandler);
    document.body.listen('click', '[data-scroll]', this._scroll);
    document.body.listen('change', '[data-submit]', this._submit);
    document.body.listen('click', '[data-load-more]', this._load);
    document.body.listen('click', 'a[data-load], button[data-load]', this._load);
    document.body.listen('change', 'select[data-load]', this._load);
    document.body.listen('submit', 'form[data-load]', this._load);
    document.body.listen('click', '[data-video]:not([data-thumb])', this._popinVideo);
    document.body.listen('click', '#search-tabs button', this._searchtab);
    document.body.listen('click', '.reset-search', this._resetForm);
    document.body.listen('change', '.search-contents form select', this.showResetBtn);
    document.body.listen('change', '.search-contents form input', this.showResetBtn);
    document.body.listen('click', '.print-btn', this.printPage);
    // document.body.listen('change', '#agreed', this._storageHandler);


    ps.subscribe('search', this._submit );
    ps.subscribe('searchafter', this._submit );

    if( document.cookie.indexOf('legalAccepted') !== -1 && document.getElementById('cookies') ){
      document.getElementById('cookies').parentNode.removeChild(document.getElementById('cookies'));
    }


    // console.log($('#cookies').innerHeight())
    logoPosition =  $('#cookies').innerHeight()
    headerHeight = $('.portail header').innerHeight()
    $('.portail .hero').css({'marginTop':headerHeight+'px'})

    if(document.querySelector('#cookies') != null)
    {
    
      
      // $('.main-logo').css({'top':logoPosition+'px'})
      if(document.querySelector('#cookies').getAttribute("aria-hidden") == "false")
      {
        
        $('.main-logo.desktop').css({'position':'absolute'})   
        $('.retailer-infos .wp img').css({'position':'absolute'})  
        $('.portail header').css({'position':'relative'})   
        $('.portail .hero').css({'marginTop':0})
      }

      else
      {
        $('.main-logo.desktop').css({'position':'fixed'})   
        $('.retailer-infos .wp img').css({'position':'fixed'})  
      
      }

    }

    if($(window).innerWidth() >= 1024)
    {
      let retailerLogoHeight =  $('.retailer-infos .wp img').innerHeight()
      let retailerPosition = (92 - retailerLogoHeight) / 2
      $('.retailer-infos .wp img').css({'top': retailerPosition + "px"})
    }





    


    $('#search-tab-1 select').each(function(){
   
      if($(this).attr('data-value') != undefined)
      {
        var currLoc = $(this).attr('data-value')
        $(this).val(currLoc)
        select.change($(this)[0]);
      }

    })

    setTimeout(function(){ 
      if($('#location-vehicle').prop("selectedIndex") > 0)
      {
        toggle._toggleHandler($('#location-vehicle')[0])
        var currRegion = $('#location-vehicle').attr('option-value')

        $('#select-region-vehicle').val(currRegion)
 
        select.change($('#select-region-vehicle')[0]);
      }

      if($('.portail .macro-search #brand').prop("selectedIndex") > 0)
      {
        select.enable($('.portail .macro-search #brand')[0]);
        
      }
    
    }, 500);


    if($('.portail .search-contents').hasClass('concession'))
    {
      $("#search-tabs button[aria-controls = 'search-tab-2']").trigger('click')
      setTimeout(function(){ 
      toggle._toggleHandler($("#search-tabs button[aria-controls = 'search-tab-2']")[0])
      $('#search-tab-2 button').trigger('click')
         


      $('html, body').animate({
        scrollTop: $('.macro-search-results').offset().top - 100
      }, 1000);

    }, 300);
    }

    this._serviceScroll(location.hash)
  }

  locate( form ){

    if( navigator.geolocation ) {

      const prevent = function(e){
        e.preventDefault();
        e.stopPropagation();
      }
      let pos = {};

      // disable form
      form.classList.add('loading');
      form.addEventListener('submit', prevent);

      // geolocate
      navigator.geolocation.getCurrentPosition(
        // success
        function( position ){
          // console.log( "User accepted the request for Geolocation and it worked:", position );
          window.userLocation = position.coords;
          for( const key in position.coords ){
            pos[key] = position.coords[key];
          }
          document.qsa('[data-position]').forEach( (input) => {
            input.value = JSON.stringify(pos);
          });

          // re-enable
          form.classList.remove('loading');
          form.removeEventListener('submit', prevent);
          ps.publish('search', form);
        },
        // error
        function( error ){
          switch(error.code) {
            case error.PERMISSION_DENIED:
              // console.log( "User denied the request for Geolocation." );
              break;
            case error.POSITION_UNAVAILABLE:
              // console.log( "Location information is unavailable." );
              break;
            case error.TIMEOUT:
              // console.log( "The request to get user location timed out." );
              break;
            case error.UNKNOWN_ERROR:
              // console.log( "An unknown error occurred." );
              break;
          }

          form.qsa('[data-locate]').forEach((option)=>{
            let parentSelect = option.closest('select');
            option.setAttribute('disabled', true);
            parentSelect.querySelector('option:not([disabled])').selected = 'selected';
            select.change( option.closest('select') );
            toggle.close({id: option.getAttribute('aria-controls')});
          });

          // re-enable
          form.classList.remove('loading');
          form.removeEventListener('submit', prevent);
        }
      );
    }

  }

  _scroll( e ){

    const trigger = e.originalEvent ? e.currentTarget : e,
          smooth = { behavior: 'smooth' },
          scrollData = trigger.dataset && trigger.dataset.scroll ? Object.assign(JSON.parse(trigger.dataset.scroll), smooth) : smooth;

    let id;

   

    

    if( e.originalEvent ){
      e.preventDefault();

      if( trigger.href ){
        id = trigger.getAttribute('href');
 
      }
    }
    else{
      id = trigger.href;
    }

    if( !("top" in scrollData) && trigger.href ){
      if( !document.querySelector( id ) ){
     
        return;
      }
      // document.querySelector(trigger.getAttribute('href')).scrollIntoView(smooth);
      scrollData.top = document.querySelector( id ).offsetTop - ( document.querySelector('header[role="banner"]') ? document.querySelector('header[role="banner"]').offsetHeight : 0) - 10;
  
    }

   
    window.scroll( scrollData );



  }

  _serviceScroll(e)
  {
    //setTimeout(function(){

    // console.log("test")
  
      if(e.length > 0)
      {
        $('html, body').animate({
          scrollTop: $(e).offset().top - 55
        }, 1000);
    //  },2000)
  
      $(e).find('.bt-serv').attr('aria-expanded', true)
      $(e).find('.content').attr('aria-hidden', false)
      }


  }

  _searchtab(e)
  {
    $('#vehicles-grid').html("")

    if(e.target.attributes['aria-controls'].nodeValue == "search-tab-2")
    {
      $('.wp-st .carousel-container').hide()
      isOccasion = false
    }

    else
    {
      $('.wp-st .carousel-container').show()
      isOccasion = true
    }
   
  }

  

  _resetForm(e)
  {
    e.preventDefault()
    // $('.reset-search').hide()
    
    // console.log(range)

    range.resetSlider()

    if($('#location-vehicle').prop("selectedIndex") > 0)
    {
      $('#search-tab-1 select option:first').prop('selected',true);  
      toggle._toggleHandler($('#location-vehicle')[0])
    }

    if($('#location-retailer').prop("selectedIndex") > 0)
    {
      $('#search-tab-2 select option:first').prop('selected',true);  
      toggle._toggleHandler($('#location-retailer')[0])
    }

    $('.macro-search form').each(function(){
      $(this)[0].reset();
    })

    
 
    $('.macro-search form select option:first').prop('selected',true);   
    $('.macro-search form select').each(function(){

      
      select.change($(this)[0]);

      if(isOccasion)
      {
        _this._submit('',$('#search-tab-1')[0])   
      }

      else
      {
        _this._submit('',$('#search-tab-2')[0])  
      }
     
       
    })
  }


  printPage()
  {
    window.print()
  }

  showResetBtn()
  {
    $('.portail .reset-search').addClass('active')
  }

  _popinVideo( e ){

    const trigger = e.currentTarget,
          video = window.config.conf.templates.video.replace('${src}', trigger.dataset.video);

    document.body.insertAdjacentHTML('beforeend', `<div class="popin"><button class="bt-close" data-close></button><div class="video-wp">${video}</div></div>`);

    document.querySelector('.popin').addEventListener('click', function(e){
      if( !e.currentTarget.closest('[class*="-wp"]') ){
        const popin = e.currentTarget.closest('.popin');
        popin.parentNode.removeChild(popin);
      }
    });

  }

  _storageHandler( e ) {

    //const trigger = e.currentTarget,
    // const trigger = document.getElementById('cookies'),
          //data = trigger.dataset.storage;
    const data = "legalAccepted=true"

    console.log(data)
    document.cookie += data;

    console.log("cookie")

  }

  _fixHeader(){

    logoPosition = $('#cookies').innerHeight()
    var menu;
  
    if($('body').hasClass('portail'))
    {
      menu = document.querySelector('header[role="banner"].fixable')
    }

    else
    {
      menu = document.querySelector('header[role="banner"].fixable.header-desktop')
    }
    
    const sticky = !!window.CSS && window.CSS.supports('position','sticky');


    if( !menu || window.istouch ){
      return;
    }

    let isAdded = false,
        menuPosition = menu.getBoundingClientRect();

    window.addEventListener('scroll', function() {

     
    
      logoPosition =  $('#cookies').innerHeight()
      headerHeight = $('.portail header').innerHeight()
      menuPosition = menu.getBoundingClientRect();
      if( 0 >= menuPosition.top && !isAdded ){
        if( !sticky ){
          menu.style.height = menu.offsetHeight+'px';
          menu.classList.add('fixed');
          $('retailer-infos').addClass('fixed')
        }
        menu.classList.add('fix');
        $('.portail header').removeClass('fix')
        $('.retailer-wrap').addClass('fix')
        $('.retailer-infos').addClass('fix')
        isAdded = true;
      }
      else if( 0 < menuPosition.top && isAdded ){
        if( !sticky ){
          menu.style.height = '';
          menu.classList.remove('fixed');
          $('.retailer-infos').removeClass('fix')
        }

        $('.retailer-wrap').removeClass('fix')
        menu.classList.remove('fix');
        $('.retailer-infos').removeClass('fix')
        isAdded = false;
      }
  
      if(window.pageYOffset > logoPosition)
      {
        $('.main-logo.desktop').css({'position':'fixed'})
        $('.retailer-infos .wp img').css({'position':'fixed'})  
        $('.portail header').css({'position':'fixed'})   
        $('.portail .hero').css({'marginTop': headerHeight+'px'})

        $('header').addClass('mob-fixed')
        $('.retailer-wrap').addClass('retailer-mob-fix')


      }

      else
      {
        $('.main-logo.desktop').css({'position':'absolute'})
        $('.retailer-infos .wp img').css({'position':'absolute'})  

        if(document.querySelector('#cookies') != null)
        {
          if(document.querySelector('#cookies').getAttribute("aria-hidden") == "true")
          {
            $('.main-logo.desktop').css({'position':'fixed'})   
            $('.retailer-infos .wp img').css({'position':'fixed'})  
            $('.portail header').css({'position':'fixed'}) 
            $('.portail .hero').css({'marginTop':headerHeight+'px'})
            $('header').addClass('mob-fixed')
            $('.retailer-wrap').addClass('retailer-mob-fix')
          }

          else
          {
            $('.portail header').css({'position':'relative'})   
            $('.portail .hero').css({'marginTop':'0'})
            $('header').removeClass('mob-fixed')
            $('.retailer-wrap').removeClass('retailer-mob-fix')
          }
        }

        else
        {
          $('.portail header').css({'position':'fixed'}) 
        }
      
      }


      if($(window).innerWidth() >= 1024)
      {
        if($('.retailer-infos').hasClass('fix'))
        {
          let retailerLogoHeightFix =  $('.retailer-infos.fix .wp img').innerHeight()
          let retailerPositionfix = (55 - retailerLogoHeightFix) / 2
          $('.retailer-infos.fix .wp img').css({'top': retailerPositionfix + "px"}) 
        }

        else
        {
          let retailerLogoHeight =  $('.retailer-infos .wp img').innerHeight()
          let retailerPosition = (92 - retailerLogoHeight) / 2
          $('.retailer-infos .wp img').css({'top': retailerPosition + "px"})

        }

  
      }

    });

  }

  _load( e ){

    e.preventDefault();
 
    const trigger = e.currentTarget,
          mode = trigger.dataset.loadMore ? 'loadMore' : 'load';

    let data = JSON.parse(trigger.dataset[mode]);

    data.params = data.params || {};
    

   // const target = document.querySelector('[data-load-content="'+ data.target +'"');
    const target = document.querySelectorAll('[data-load-content="'+ data.target +'"]')[0];

     if(data.target == "product")
     {
      
      // document.querySelectorAll('.block-product').classList.add('active')
      $('.block-product').each(function(){
        $(this).removeClass("active")
      })    

      e.currentTarget.parentNode.classList.add('active')

     }

    if( trigger.form || trigger.hasAttribute('action') ){
      let elt = trigger.hasAttribute('action') ? trigger : trigger.form;
      data.params = Object.assign( formUtils.toJSON(elt), data.params );

    }

    if( trigger.dataset.params ){
      data.params = Object.assign( JSON.parse(trigger.dataset.params), data.params );
    }

    for( let key in data.params ){
      if( data.params[key].length == 0 ){
        delete data.params[key];
      }
    }

    data.url = trigger.closest('form') ? trigger.closest('form').action : (data.url || trigger.href);

    target.classList.add('loading');

    fetcher.get( data.url, { data: data.params } )
      .then( (body) => {

        setTimeout(function(){ new LazyLoad(); console.log('lazy load') }, 1000);
          
        if( mode == 'load' ){
          target.innerHTML = body.data.html;
        }
        else{
          target.insertAdjacentHTML('beforeend', body.data.html);
        }

        if( body.data.endReached ){
          trigger.classList.add('disabled');
        }
        if( data.slider ){
          const sliderParams = JSON.parse( target.querySelector('[data-sliderconfig]').dataset.sliderconfig );
          

          if( sliderParams.slidesPerView < target.querySelectorAll('.swiper-slide').length ){
            sliders.init(target);
            target.classList.remove('unslidered');
          }
          else{
            target.classList.add('unslidered');
          }

        }
        if( body.data.params ){
          trigger.dataset.params = body.data.params;
        }
        if( data.scroll ){
          this._scroll({href: '#'+target.id})
        }
        target.classList.remove('loading');
      });

  }

  _submit( e, elt ){

  //  console.log("submit:",e);
  // console.log(elt)
    if( e.target && e.target.dataset.populate ){
      return;
    }

    window.requestAnimationFrame(function(){

      // do not submit if data-nosubmit option (case of location) -> triggered by this.locate
      if( e.target && e.target.selectedIndex && e.target.querySelectorAll('option')[e.target.selectedIndex].hasAttribute('data-nosubmit') ){
        return;
      }

      const trigger = elt || e.currentTarget;
      
      let data = trigger.dataset.submit ? JSON.parse( trigger.dataset.submit ) : null,
          params = formUtils.toJSON(trigger);

      data.url = data.url ? data.url : trigger.action;

      

      for( let key in params ){
        if( params[key].length == 0 ){
          delete params[key];
        }
      }

      let request = fetcher.get( data.url, { data: params } );
     
    
    

      if( data.updateText ){
        
        request.then((body) => {
   
          document.qsa('[data-text-update="'+ data.updateText +'"]').forEach( (updateElt) =>{
            const bt = updateElt.closest('button');

         

            if(body.data.results == 0)
            {
              updateElt.parentNode.innerHTML = "Aucun résultat<span data-text-update="+data.updateText+"></span>"
              
              //"Aucun résultat";
            }
  
            else
            {
              if(isOccasion)
              {
                updateElt.parentNode.innerHTML = "Voir le(s) <span data-text-update="+data.updateText+">"+body.data.results+"</span> véhicules(s)"
              }

              else
              {
                updateElt.parentNode.innerHTML = "Voir le(s) <span data-text-update="+data.updateText+">"+body.data.results+"</span> concession(s)"
              }
             
            }
            if( body.data.html ){
              document.querySelector('[data-load-content="'+ JSON.parse(trigger.dataset.load).target +'"]').innerHTML = body.data.html;
              if( body.data.results == 0 && bt ){
                bt.classList.add('no-results');
                bt.setAttribute('disabled', true);
              }
            }
            else if( bt ){
              bt.classList.remove('no-results');
              bt.removeAttribute('disabled');
            }
          });
        });
      }

    })

  }

}

export default new Misc();
