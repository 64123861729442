
/**
 * Gallery
 * @constructor
 */

class Gallery{

  constructor() {

    this.bind = this.bind.bind(this);
    this.activate = this.activate.bind(this);
    this.nav = this.nav.bind(this);

    this.bind();

  }

  bind() {

    document.body.listen('click', '[data-gallery] [data-thumb]:not(.active)', this.activate );
    document.body.listen('click', '[data-gallery] [data-controls]', this.nav );

  }

  nav(e){

    const trigger = e.currentTarget,
          dir = trigger.dataset.controls == 'prev' ? -1 : 1,
          controls = trigger.closest('[data-gallery]').querySelector('.thumbnails'),
          active = controls.querySelector('[data-thumb].active').parentNode;

          console.log(active, dir)

    if( dir < 0 ){
      if( active.previousElementSibling ){
        console.log("1a")
        active.previousElementSibling.querySelector('button').click();
      }
      else{
        console.log("2a")
        controls.querySelector('li:last-child button').click();
      }
    }

    if( dir > 0 ){
      if( active.nextElementSibling ){
        console.log("1b")
        active.nextElementSibling.querySelector('button').click();
      }
      else{
        console.log("2b")
        controls.querySelector('li:first-child button').click();
      }
    }

  }

  activate(e) {

    e.preventDefault();

    const trigger = e.currentTarget,
          gallery = trigger.closest('[data-gallery]');

    let viewer = gallery.querySelector('[data-viewer]'),
        newViewer = viewer.cloneNode();

    const container = viewer.parentNode,
          video = container.querySelector('iframe');

    if( container.classList.contains('show') ){
      return;
    }

    // handle trigger button state
    gallery.querySelector('[data-thumb].active').classList.remove('active');
    trigger.classList.add('active');

    // handle content
    if( trigger.dataset.video ){
      if( video ){
        video.src = trigger.dataset.video;
      }
      else{
        container.insertAdjacentHTML('beforeend', window.config.conf.templates.video.replace('${src}', trigger.dataset.video));
      }
      return;
    }

    if( video ){
      container.removeChild(viewer);
      // remove video when done
      viewer = video;

    }

    newViewer.src = trigger.querySelector('img').src;
    newViewer.srcset = trigger.querySelector('img').srcset;

    container.classList.add('show');
    container.appendChild(newViewer);

    viewer.addEventListener('transitionend', function(){
      container.removeChild(viewer);
      container.classList.remove('show');
    }, { once: true });

  }


}
export default new Gallery();
