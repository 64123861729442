import ps from 'pubsub-js';

/**
 * Range
 * @constructor
 * @requires pubsub-js
 */
class Range{

  /**
   * Inits the module with necessary functions
   * @method
  **/
  constructor(){

    if( !document.querySelector('[data-range]') ){
      return;
    }

    this.bind = this.bind.bind(this);
    this.init = this.init.bind(this);
    this.resetSlider = this.resetSlider.bind(this)

    this.init();
  }

  bind(){

    document.body.listen('keydown', '[data-handle]', (e) =>{
      const trigger = e.currentTarget,
            range = trigger.closest('[data-range]'),
            index = trigger.dataset.handle,
            step = range.noUiSlider.steps()[index],
            intercept = [37, 38, 39, 40].indexOf(e.which) !== -1;

      let value = Number( range.noUiSlider.get()[index] );

      if( !intercept ){
        return;
      }

      // right
      if ( e.which === 37 ) {
        value = value - step[0];
      }
      // down
      if ( e.which === 40 ) {
        e.preventDefault();
        value = value - step[0]*5;
      }

      // left
      if ( e.which === 39 ) {
        value = value + step[1];
      }
      // up
      if ( e.which === 38 ) {
        e.preventDefault();
        value = value + step[1]*5;
      }

      const values = [ ( index == 0 ? value : null ), ( index == 1 ? value : null ) ];

      if ( intercept ) {
        range.noUiSlider.set( values );
      }

    });

  }


  init(){

    const rangeItems = document.qsa('[data-range]'),
          defaultData = {
            connect: [ false, true, false ],
            behaviour: 'tap'
          };

    rangeItems.forEach( (rangeItem) => {

      let data = JSON.parse( rangeItem.dataset.range ),
          val = ( data.prefix ? data.suffix : '' ) + '${value}' + ( data.suffix ? data.suffix : '' );

      data = Object.assign( data, defaultData );

      data.tooltips = [ {
        to: function ( value ) {
          return val.replace('${value}', Math.round(value).toLocaleString(window.locale));
        }
      }, {
        to: function ( value ) {
          return val.replace('${value}', Math.round(value).toLocaleString(window.locale));
        }
      } ];

      data.pips = {
        mode: 'range',
        density: data.pipsDensity,
        format: {
          to: function ( value ) {
            return val.replace('${value}', Math.round(value).toLocaleString(window.locale));

          }
        }
      }

      const range = noUiSlider.create(rangeItem, data);

      range.on('update', (values) => {
        document.querySelector('input[name="'+ rangeItem.id +'-min"]').value = values[0];
        document.querySelector('input[name="'+ rangeItem.id +'-max"]').value = values[1];
        
      });
      range.on('change', () => {

        $('.portail .reset-search').addClass('active')
        if( rangeItem.closest('form') ){
          ps.publish('search', rangeItem.closest('form'));
        }
      });

    });

    this.bind();

  }

  resetSlider()
  {
    var engineSlider = document.getElementById('engine');
    var PriceSlider = document.getElementById('price');

    engineSlider.noUiSlider.reset()
    PriceSlider.noUiSlider.reset()

  }

}

export default new Range();
