/* global module */

/**
 * Generic module to handle scroll and resize event in a healthy and performant way (throttling etc)
 * @module listener
 * @requires lodash/debounce
 * @requires pubsub-js
**/

import _debounce from 'lodash/debounce';
import ps from 'pubsub-js';

/**
 * @exports listener
 * @constructor
**/
function Listener(){

  /**
   * Object to store datas
   * @property {object} data - Default data
   * @property {number} data.delta - Scroll amount delta
   * @property {string} data.direction - Scroll direction [up|down]
   * @property {number} data.pos - Scroll position in pixels
  **/
  const data = {
    delta: 0,
    direction: 'down',
    pos: null
  };

  /**
   * Set up all event listeners
   * @method
  **/
  function bind() {
    // Scroll event
    window.addEventListener( 'scroll', scroll, false );

    // Resize event
    window.addEventListener( 'resize', _debounce( resize, 150 ));
  }

  /**
   * Stores all DOM elements and assign default properties
   * @method
  **/
  function dom() {
    data.width = window.innerWidth;
    data.height = window.innerHeight;
  }

  /**
   * Inits the module with necessary functions
   * @method
  **/
  function init() {

    scroll();
    resize();

    dom();
    bind();
  }


  /**
   * Get scroll position
   * @method
  **/
  function getPos() {
    set();

    return {
      direction: data.direction,
      pos: data.pos
    };
  }


  /**
   * Triggers resize event with all corresponding data
   * @method
  **/
  function resize() {
    set();

    const width = window.innerWidth;
    const height = window.innerHeight;
    const params = {
      direction: data.direction,
      pos: data.pos,
      delta: data.delta,
      height: false,
      width: false
    };

    if( !data.width || width !== data.width ) {
      params.width = width;
    }

    if( !data.height || height !== data.height ) {
      params.height = height;
    }

    isTouch();

    // Trigger custom event
    ps.publish( 'resize', params );

    // Store new dimensions
    data.height = height;
    data.width = width;
  }


  /**
   * Trigger custom scroll event with necessary data
   * @method
  **/
  function scroll() {

    set();

    // Trigger custom event
    ps.publish( 'scroll', {
      direction: data.direction,
      pos: data.pos,
      delta: data.delta
    });
  }

  /**
   * Check if device is (probably) a touch device
   * @method
  **/
  function isTouch(){
    // detect touch devices
    if( !!(("ontouchstart" in window) || window.navigator && window.navigator.msPointerEnabled && window.MSGesture || window.DocumentTouch && document instanceof DocumentTouch) && window.matchMedia( window.config.mq.touch ).matches ){
      document.documentElement.classList.add('touch');
      document.documentElement.classList.remove('no-touch');
      window.istouch = true;
    }
    else {
      document.documentElement.classList.add('no-touch');
      document.documentElement.classList.remove('touch');
      window.istouch = false;
    }
  }

  /**
   * Store scroll data
   * @method
  **/
  function set(){
    const pos = document.documentElement.scrollTop || document.body.scrollTop;
    let direction = 'down';

    if ( data.pos === pos ) {
      return;
    }

    if ( data.pos > pos ) {
      direction = 'up';
    }

    if ( direction === data.direction ) {
      data.delta += Math.abs( data.pos - pos );
    }
    else {
      data.delta = 0;
    }

    // Store position
    data.direction = direction;
    data.pos = pos;
  }

  // start module
  ps.subscribe( 'app.start', init );


  return {
    getPos: getPos
  };
}

export default Listener();
